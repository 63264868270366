import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import http from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default function useCertificatesList() {
  // Use toast
  const toast = useToast()

  const refCertificateListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'active',
      label: 'Статус активе',
      sortable: false,
    },
    {
      key: 'title',
      label: 'Наименование',
      sortable: true,
    },
    {
      key: 'for_quiz',
      label: 'Для',
      sortable: false,
    },
    {
      key: 'image',
      label: 'Шаблон',
      sortable: false,
    },
    {
      key: 'actions',
      label: 'Действие',
    },
  ]
  const perPage = ref(10)
  const totalCertificates = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('title')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refCertificateListTable.value ? refCertificateListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCertificates.value,
    }
  })

  const refetchData = () => {
    refCertificateListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchCertificates = (ctx, callback) => {
    http
      .post('https://edu.startupchoikhona.tj/backend/api/certificate/index', {
        search: searchQuery.value,
        length: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const certificates = response.data.data.items
        callback(certificates)
        totalCertificates.value = response.data.data.total_rows
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка выборки список сертификатов',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteCertificate = (idCertificate) => {
    Swal.fire({
      title: 'Вы действительно хотите удалить этот запись?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Удалить',
      customClass: {
        cancelButton: 'btn btn-outline-danger ml-1',
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
      .then(result => {
        if (result.value && idCertificate > 0) {
          http
            .post(`https://edu.startupchoikhona.tj/backend/api/certificate/${idCertificate}/delete`, {})
            .then(response => {
              refetchData()
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Успешно удалено.',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Ошибка при удалении запись шаблона',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Удаление запись шаблон отменено',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const changeActive = (idCertificate) => {
    http
      .post(`https://edu.startupchoikhona.tj/backend/api/certificate/${idCertificate}/active`, {})
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Успешно',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            text: response.data.message,
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка при удалении пользователя',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        })
      })
  }

  return {
    fetchCertificates,
    deleteCertificate,
    changeActive,
    tableColumns,
    perPage,
    currentPage,
    totalCertificates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCertificateListTable,
    refetchData,
  }
}
